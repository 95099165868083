import React from 'react';
import './Banner.css'; // You'll need to create a corresponding CSS file

const Banner = () => {
  return (
    <div className="banner">
      <div className="banner-content">
        <h1> NebulaTrade.com</h1>
        {/* <p>Your one-stop solution for everything you need</p> */}
        
      </div>
    </div>
  );
};

export default Banner;
