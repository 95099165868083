import React from 'react'
import CartBody from '../component/akhilesh/Cart/CartBody'
import Snavbar from '../component/akhilesh/Shopping/Snavbar'
import Footer from '../Kaushik/Footer'





const Cartpage = () => {
  return (
    <>
<Snavbar/>
  <CartBody/>
  <Footer/>

    </>
  )
}

export default Cartpage